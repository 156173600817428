<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn small color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12"> Tên cấu hình: {{ name }} </v-col>
        <v-col cols="12"> Mã cấu hình: {{ code }} </v-col>
      </v-row>

      <div>
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th
                  role="columnheader"
                  class="text-center"
                  style="min-width: 120px"
                >
                  Phần việc
                </th>
                <th role="columnheader" class="text-center">
                  Thời gian tối thiểu
                </th>
                <th role="columnheader" class="text-center">Min. Chưa xử lý</th>
                <th
                  role="columnheader"
                  class="text-center"
                  style="width: 140px"
                >
                  Loại giới hạn
                </th>
                <th role="columnheader" class="text-center">Min. Cần xử lý</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="item in items" :key="item.id">
                <td>{{ $t(`labels.work_type_${item.work_type}`) }}</td>
                <td>{{ item.wait_time }} <i>phút</i></td>
                <td>{{ item.wait_quantity }}</td>
                <td>{{ item.type == 1 ? "Sản phẩm" : "Đơn hàng" }}</td>
                <td>{{ item.process_quantity }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  WARNING_CONFIG_DEFAULT,
  ITEM_TYPES,
  WARNING_TYPES,
} from "@/libs/const";

export default {
  name: "WarningDetailConfig",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [...WARNING_CONFIG_DEFAULT],
    typeOptions: [...WARNING_TYPES],
    itemTypeOptions: [...ITEM_TYPES],
    name: null,
    code: null,
  }),
  computed: {
    isDisabledBtn() {
      if (!this.name) {
        return true;
      }

      const checkItem = [...this.items].find(
        (item) =>
          !item.wait_time ||
          !item.wait_quantity ||
          !item.process_quantity ||
          !item.type
      );
      return !!checkItem;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getDetail() {
      if (!this.config || !this.config.id) {
        return false;
      }
      httpClient
        .post("/employee/v1/warning-detail-config", { id: this.config.id })
        .then(({ data }) => {
          this.name = this.config.name;
          this.code = this.config.code;
          this.items = [...data];
        });
    },
  },
};
</script>

<style scoped></style>
